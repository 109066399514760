import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-short-card',
  template: `
    <div class="short-card-top">
      <ng-container [ngTemplateOutlet]="topTemplate">
      </ng-container>
    </div>
    <mat-card class="short-card">
      <mat-card-content class="mat-card-content-class">
        <div class="card-div">
        <ng-container [ngTemplateOutlet]="bodyTemplate">
        </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
  `,
  styleUrls: ['../../../../styles/card-reuse.css']
})


export class ShortCardComponent implements OnInit {
  @Input() bodyTemplate!: TemplateRef<any>;
  @Input() topTemplate!: TemplateRef<any>;

  constructor() {

  }

  ngOnInit(): void {

  }

}
