import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-badge',
  template: `
  `,
  styleUrls: ['../../../styles/badge.css']
})
export class BadgeComponent implements OnInit {

   constructor() { }

   ngOnInit(): void { }
}
