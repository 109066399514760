import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/api/authentication/authentication.service';
import { Select } from '@ngxs/store';
import { AppState } from '../../../../store/app.state';
import { User } from '../../../models/user-model';
import { MenuItem, MenuColors } from '../menu-item/menu-item-model';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavBarComponent implements OnInit, OnDestroy {
  userProfileSubscription: Subscription | undefined;
  iconBasePath = '../../assets/icons/sidebar';

  @Select(AppState.getUserProfile) $userProfile!: Observable<User>;
  @Input() backgroundColor: string = MenuColors.main_background;
  @Input() activeItemColor: string = MenuColors.main_active_background;
  @Input() primaryFontColor: string = MenuColors.primary_font_color;
  @Input() activeFontColor: string = MenuColors.active_font_color;
  @Input() subMenuIconColor: string = 'white';
  @Input() isCollapsed: boolean = false;
  @Input() isSubmenu: boolean = false;
  @Input() menuItems: MenuItem[] = [];

  @Output() itemClicked = new EventEmitter<string>();

  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  navigateTo(route: string) {
    return () => {
      this.router.navigateByUrl(route);
    };
  }

  ngOnInit(): void {}

  onItemClick(item: MenuItem): void {
    console.log("Item clicked:", item.title);
  //   this.itemClicked.emit(item.title);
  }

  getMenuItemClick(item: any): void {
    console.log("NavBar Item clicked:", item);
    this.itemClicked.emit(item);
  } 

  ngOnDestroy(): void {
    if (this.userProfileSubscription) {
      this.userProfileSubscription.unsubscribe();
    }
  }
}
