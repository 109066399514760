import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-signup-card',
  template: `
      <div class="signup-container">
         <div class="signup-card">
            <ng-container [ngTemplateOutlet]="bodyTemplate">
            </ng-container>
         </div>
         <img *ngIf="showSvgs" class="first-svg show_svgs" src="assets/icons/first-signup.svg" alt="first-signup-svg">
         <img *ngIf="showSvgs" class="second-svg show_svgs" src="assets/icons/second-signup.svg" alt="first-signup-svg">
      </div>
   `,
  styleUrls: ['../../../../styles/signup-card.css']
})
export class SignupCardComponent implements OnInit {
   @Input() text: string = '';
   @Input() showSvgs: boolean = true;
   @Input() bodyTemplate!: TemplateRef<any>;
   
   constructor() {}

   ngOnInit(): void {}   
}