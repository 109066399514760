<div class="tableDiv">
  <ng-container class="mat-elevation-z8" tabindex="0">
    <!-- <br> -->
    <!-- Table -->
    <div class="table-classes {{tableDataClass}}" *ngIf="showTableData">
      <table mat-table [dataSource]="tableDataSource" matSort (matSortChange)="sortTable($event)">
        <!-- ICON -->
        <ng-container *ngIf="rowActionIcon?.length" [matColumnDef]="rowActionIcon">
          <th mat-header-cell *matHeaderCellDef>  </th>
          <td mat-cell *matCellDef="let element" [id]="rowActionIcon ? rowActionIcon : 'more_vert'" [matMenuTriggerFor]="beforeMenu">
            <mat-button-toggle value="center" class="table-icon">
              <mat-icon>{{ rowActionIcon }}</mat-icon>
            </mat-button-toggle>
            <mat-menu #beforeMenu="matMenu" class="mat-menu" xPosition="before">
              <button class="menu-toggle-button" *ngIf="viewActionText?.length" mat-menu-item (click)="emitViewAction(element)">
                <mat-icon class="view-icon" *ngIf="viewActionIcon">{{ viewActionIcon }}</mat-icon>
                <span>{{ viewActionText }}</span>
              </button>
              <button class="menu-toggle-button" *ngIf="editActionText?.length" mat-menu-item (click)="emitEditAction(element)">
                <mat-icon class="edit-icon" *ngIf="editActionIcon">{{ editActionIcon }}</mat-icon>
                <span>{{ editActionText }}</span>
              </button>
              <button class="menu-toggle-button" *ngIf="deleteActionText?.length" mat-menu-item (click)="emitDeleteAction(element)">
                <mat-icon class="delete-icon" *ngIf="deleteActionIcon">{{ deleteActionIcon }}</mat-icon>
                <span>{{ deleteActionText }}</span>
              </button>
              <button class="menu-toggle-button" *ngIf="extraActionText?.length" mat-menu-item (click)="emitExtraAction(element)">
                <mat-icon class="delete-icon" *ngIf="extraActionIcon">{{ extraActionIcon }}</mat-icon>
                <span>{{ extraActionText }}</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container *ngIf="statusColumn?.length" [matColumnDef]="statusColumn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{statusColumn}}
          </th>
          <td mat-cell *matCellDef="let element" [ngClass]="getStatusClass(element[statusColumnName])">
            <span>
              {{element[statusColumnName]}}
              <!-- {{element[statusColumn] | titlecase }} -->
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="dateColumn?.length" [matColumnDef]="dateColumn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{dateColumn}}
          </th>
          <td mat-cell *matCellDef="let element">
            {{element[dateColumnName]| date: "YYYY/MM/dd, h:mm:ss a"}}
            <!-- {{element[dateColumnName]| date: "MM/dd/YYYY"}} -->
          </td>
        </ng-container>

        <ng-container *ngFor="let tableColumn of tableColumns" [matColumnDef]="tableColumn.name">
          <!-- if sortable column header -->
          <ng-container *ngIf="tableColumn.isSortable; else notSortable">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="{{tableColumn.name}}"
              [arrowPosition]="tableColumn.position === 'right' ? 'before' : 'after'">
              {{tableColumn.name}}
            </th>
          </ng-container>
          <!-- else not sortable -->
          <ng-template #notSortable>
            <th mat-header-cell *matHeaderCellDef [class.text-right]="tableColumn.position == 'right'">
              {{tableColumn.name}}
            </th>
          </ng-template>
          <!-- column data -->
          <td mat-cell *matCellDef="let element" [class.text-right]="tableColumn.position == 'right'" [ngClass]="getStatusClass(element[tableColumn.dataKey])">
            <!-- {{element | dataPropertyGetterPipe: tableColumn.dataKey}} -->
            <span>
              {{ (tableColumn.dataKey.toLowerCase().includes('amount') || tableColumn.dataKey.toLowerCase().includes('charge') || tableColumn.dataKey.toLowerCase().includes('price') || tableColumn.dataKey === 'tellerLimit') ? (element[tableColumn.dataKey] | number) : element[tableColumn.dataKey] }}
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
        </tr>
        <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row, $event)" [attr.data-id]="row.id">
        </tr>
      </table>
    </div>
    <div class="table-classes emptyState {{noTableDataClass}}" *ngIf="!showTableData">
      <div class="emptystate-div">
        <div>
          <img src="assets/icons/light-empty-state.svg" alt=""/>
        </div>

        <div>
          <div class="mainText">
            {{failureHeaderText}}
          </div>
          <div class="subText">
            {{failureSubText}}
          </div>
        </div>
       </div>
    </div>

    <!-- Paginations -->
    <mat-paginator class="table-paginator" *ngIf="isPageable" [pageSizeOptions]="paginationSizes"
      [pageSize]="defaultPageSize" showFirstLastButtons>
    </mat-paginator>
  </ng-container>
</div>
