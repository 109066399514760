import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../api/authentication/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { BootstrapNotifyService } from './bootstrapNotify/bootstrap-notify.service';

@Injectable({
   providedIn: 'root'
})

export class InactivityService {
   private timeoutId: any;
   private readonly TIMEOUT = 10 * 60 * 1000; // 10 minutes

   constructor (
      private router: Router,
      private ngZone: NgZone,
      protected dialog: MatDialog,
      protected bootstrapNotifyService: BootstrapNotifyService,
      private authenticationService: AuthenticationService
   ) {
      this.initListener();
      this.initTimeout();
   }

   initListener() {
      this.ngZone.runOutsideAngular(() => {
         ['click', 'mousemove', 'keypress', 'scroll'].forEach(event => 
            window.addEventListener(event, () => this.resetTimeout(), true)
         );
      });
   }

   resetTimeout() {
      if (this.timeoutId) {
         clearTimeout(this.timeoutId);
      }
      this.initTimeout();
   }

   initTimeout() {
      this.ngZone.runOutsideAngular(() => {
         this.timeoutId = setTimeout(() => {
            this.ngZone.run(() => this.logout());
         }, this.TIMEOUT);
      });
   }

   logout() {
      this.dialog.closeAll();
      this.bootstrapNotifyService.info("You have been logged out due to Inactivity");
      this.authenticationService.logOut();
   }
}
