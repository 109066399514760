export class RowItem {
  title: string;
  subtitle: string;
  icon: string;
  onClickHandler?: () => void;

  constructor({
    title,
    subtitle = '',
    icon = '',
    onClickHandler,
  }: {
    title: string;
    subtitle?: string;
    icon?: string;
    onClickHandler?: () => void;
  }) {
    this.title = title;
    this.subtitle = subtitle;
    this.icon = icon;
    this.onClickHandler = onClickHandler ?? undefined;
  }
}
