import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// Template and UI Design Implementation by Iruðbe_Akhigbe_Ayðmíde
@Component({
  selector: 'app-flat-button',
  template : `
    <button
      mat-flat-button
      [type]="type"
      (click)="onClick()"
      [disabled]="isDisabled"
      class="btn">
      <span
        [style.color]="color"
        [style.font-family]="fontFamily">
        {{ buttonText }}
      </span>
    </button>
  `,
  styleUrls: ['./flat-button.component.css']
})

export class FlatButtonComponent implements OnInit {
  @Input()
	set text(name: string) {
		this.buttonText = name;
	}
	get name(): string {
		return this.buttonText;
	}

  @Input() customClass!: string;
  @Input() matIconText!: string;
	@Input() color: string = '';
	@Input() fontFamily: string = '';
	@Input() type: string = 'button';
	@Input() buttonIcon: string = '';
  @Output() btnClick = new EventEmitter();
	@Input() isDisabled!: boolean;

	public buttonText = '';
  constructor() { }

  ngOnInit() {
  }

  onClick() {
		this.btnClick.emit();
	}

}
