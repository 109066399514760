import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { SubscriptionManagementDirective } from 'src/app/core/directives/subscription-management.directive';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-loader',
  template: `     
   <div *ngIf="loading" class="overlay">
      <div class="costaff-spinner"></div>
   </div>
  `,
  styleUrls: ['../../../styles/loader.css']
})


export class LoaderComponent extends SubscriptionManagementDirective implements OnInit {
  loading = false;

  constructor(
    public loaderService: LoaderService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.loaderService.isLoading.pipe(takeUntil(this.unSubscribe)).subscribe((isLoading: boolean) => {
      console.log(isLoading);
      this.loading = isLoading;
      if (isLoading) {
        this.disableUserInput();
      } else {
        this.enableUserInput();
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: BeforeUnloadEvent) {
    console.log(event);
  }

  // Disable keyboard and mouse events
  @HostListener('keydown', ['$event'])
  @HostListener('mousedown', ['$event'])
  disableInputEvents(event: KeyboardEvent | MouseEvent) {
    if (this.loading) {
      event.preventDefault();
      event.stopPropagation();
      this.cdRef.detectChanges();
    }
  }

  disableUserInput() {
    // Add a class to the document body to prevent scrolling
    document.body.classList.add('no-scroll');
  }

  enableUserInput() {
    // Remove the class to enable scrolling again
    document.body.classList.remove('no-scroll');
  }

}
