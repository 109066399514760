import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card',
  template: `
    <mat-card class="special-dashboard-class">
      <mat-card-content class="empty-content {{CardClass}}">
      </mat-card-content>

      <mat-card-content class="card-content">
        <p class="first">{{CardTitle}}</p>

        <p class="second">{{CardAmount}}</p>

        <!-- <a routerLink="{{CardLink}}" class="{{CardClass}}">
         {{CardLinkTitle}}
        </a> -->
        <p class="third">{{CardCount}}</p>
      </mat-card-content>
    </mat-card>
  `,
  styleUrls: ['../../../styles/cards.css']
})


export class CardComponent implements OnInit {
  @Input() CardTitle!: string;
  @Input() CardAmount!: any;
  @Input() CardCount!: number;
  @Input() CardLink!: any;
  @Input() CardClass!: any;
  @Input() CardLinkTitle!: string;

  constructor() {

  }

  ngOnInit(): void {

  }

}
