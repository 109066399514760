import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { dateRangeRequest } from '../../models/iresponse';
import { TableColumn } from './table-properties/table-columns';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/api/authentication/authentication.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})

export class TableComponent implements  OnInit, AfterViewInit {
  isLoggedIn: boolean = false;
  isLoggedInSubscription: Subscription | undefined;
  activeStatusName : string = "Deactivate";
  @Input() searchInput: any;
  @Input() gridClass: any;
  @Input() specialFirstButtonValue: any;
  @Input() specialSecondButtonValue: any;
  @Input() tableDataClass: any;
  @Input() noTableDataClass: any;
  @Input() badgeNumber: number = 0;
  @Output() filteredDataChange = new EventEmitter<any[]>();
  rangeError: boolean = false;
  showApplyBtn: boolean = true;
  showClearBtn: boolean = false;
  @Input() showFirstSpecialButton = false;
  @Input() showSecondSpecialButton = false;
  public tableDataSource = new MatTableDataSource([]);
  public displayedColumns!: string[];
  @ViewChild(MatPaginator, {static: false}) matPaginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) matSort!: MatSort;
  lastClickedRow: any;

  showDateRange: boolean = false;
  @Input() isPageable = false;
  @Input() isSortable = false;
  @Input() isFilterable = false;
  @Input() isStatus = true;
  @Input() isDate = true;
  @Input() isDateRange = false;
  @Input() showTableData = false;
  @Input() showFilterPage = false;
  @Input() removeFilterPage = false;
  @Input() tableColumns: TableColumn[] = [];
  @Input() failureHeaderText: string = 'No Data Available';
  @Input() failureSubText: string = 'Please try again later.';  
  @Input() statusColumn!: string;
  @Input() dateColumn!: string;
  @Input() statusColumnName: string = "Status";
  @Input() rowActionIcon!: string;
  @Input() paginationSizes: number[] = [5, 10, 25, 50];
  @Input() defaultPageSize = this.paginationSizes[1];
  @Input() viewActionText! : string;
  @Input() editActionText!: string;
  @Input() deleteActionText!: string;
  @Input() extraActionText!: string;
  @Input() viewActionIcon?: string;
  @Input() editActionIcon?: string;
  @Input() extraActionIcon?: string;
  @Input() deleteActionIcon?: string;
  @Input() deactivateIcon?: string;
  @Input() deactivateText?: string;
  @Input() activateIcon?: string;
  @Input() activateText?: string;
  @Input() dateColumnName!: string;
  @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();
  startDates! : any;
  endDates!: any;

  @Output() sort: EventEmitter<Sort> = new EventEmitter();
  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() editAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() extraAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() deactivateAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() activateAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchDate: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadTable: EventEmitter<any> = new EventEmitter<any>();
  @Output() btnClick = new EventEmitter();
  @Output() specialFirstButtonClick = new EventEmitter();
  @Output() specialSecondButtonClick = new EventEmitter();

  // this property needs to have a setter, to dynamically get changes from parent component
  @Input() set tableData(data: any[]) {
    if (data && data.length > 0) {
      // console.log(data);
      this.setTableDataSource(data);
    }
  }

  currentDate = new Date();

  maxDate: string = '';
  // maxDate =  new Date(`${this?.currentDate.getMonth()+ 1}-${this.currentDate.getUTCDate()+1}-${this.currentDate.getUTCFullYear()}`).toISOString().slice(0,10);


  dateRangeForm = new FormGroup({
    startDate :new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required]),
  },{validators: [this.dateRangeValidator]});

  dateRangeValidator(dateRangeForm: AbstractControl) : ValidationErrors | null {
    const startDate = dateRangeForm.get('startDate')!.value;
    const endDate = dateRangeForm.get('endDate')!.value;
    if (startDate && endDate) {
      // return startDate < endDate ? null : { rangeError: true };
      const start = new Date(startDate);
      const end = new Date(endDate);
      return start < end ? null : { rangeError: true };
    }
    return null;
  }

  dateRangePayload():dateRangeRequest {
    const startVal: any = this.dateRangeForm.value.startDate;
    const endVal: any = this.dateRangeForm.value.endDate;

    let startDate = new Date(startVal).toString();
    let endDate = new Date(endVal).toString();
    const startNewDate = new Date(startDate);
    const endNewDate = new Date(endDate);
    this.startDates = startNewDate.getFullYear().toString() + '-' + (startNewDate.getMonth() + 1).toString().padStart(2,'0') + '-' + startNewDate.getDate().toString().padStart(2,'0') + 'T00:00:00.000Z'
    this.endDates = endNewDate.getFullYear().toString() + '-' + (endNewDate.getMonth() + 1).toString().padStart(2,'0') + '-' + endNewDate.getDate().toString().padStart(2,'0') + 'T23:59:59.000Z'
    sessionStorage.setItem('datePickerStart', this.startDates);
    sessionStorage.setItem('datePickerEnd', this.endDates);
    return{
      fromDate: this.startDates,
      toDate: this.endDates
    }
  }

  showStatusCloumn: boolean = false;

  constructor(private authenticationService: AuthenticationService
  ) {
    this.lastClickedRow = null; // Initialize with a default value
  }

  ngOnInit():  void {
    
    const nextDay = new Date(this.currentDate);
    nextDay.setDate(this.currentDate.getDate() + 1);
    this.maxDate = nextDay.toISOString().slice(0, 10);

    if (this.tableColumns) {
      const columnNames = this.tableColumns.map((tableColumn: TableColumn) => tableColumn.name);

      if (this.rowActionIcon || this.statusColumn || this.dateColumn) {
        this.displayedColumns = [
          ...columnNames,
          ...(this.dateColumn ? [this.dateColumn] : []),
          ...(this.statusColumn ? [this.statusColumn] : []),
          ...(this.rowActionIcon ? [this.rowActionIcon] : [])
        ];
      } else {
        this.displayedColumns = columnNames;
      }
      
      // if (this.rowActionIcon || this.statusColumn || this.dateColumn) {
      //   this.displayedColumns = [ 
            // ...columnNames, 
            // this.dateColumn, 
            // this.statusColumn, 
            // this.rowActionIcon
            // ];

      // } else {
      //   this.displayedColumns = columnNames;
      // };
    }
  }

  showFilterPages() {
    // event.stopPropagation();
    this.btnClick.emit();
  }

  onSearchDate() {
    this.dateRangePayload();
    this.showApplyBtn = false;
    this.showClearBtn = true;
    this.searchDate.emit();
  }

  onReloadTable() {
    this.dateRangeForm.reset();
    this.showApplyBtn = true;
    this.showClearBtn = false;
    this.rangeError = false;
    this.reloadTable.emit();
  }
  

  // we need this, in order to make pagination work with *ngIf
  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.matPaginator;
    // console.log(this.tableDataSource.paginator);
    this.tableDataSource.sort = this.matSort;
  }

  setTableDataSource(data: any) {
    // this.tableDataSource = new MatTableDataSource(data);
    this.tableDataSource.data = data;
    this.tableDataSource.paginator = this.matPaginator;
    if (this.tableDataSource.paginator) {
      console.log(this.tableDataSource.paginator);
    }
    this.tableDataSource.sort = this.matSort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
    
    // Emit the filtered data
    this.filteredDataChange.emit(this.tableDataSource.filteredData);
  }

  viewDateRange(){
    this.showDateRange = !this.showDateRange;
  }
  // sortTable(sortParameters: Sort)
  sortTable(sortParameters: Sort) {
    // defining name of data property, to sort by, instead of column name
    //sortParameters.active = this.tableColumns.find(column => column.name === sortParameters.active).dataKey;
    // sortParameters.active = this.tableColumns.find(tableColumn => tableColumn.name === sortParameters.active)?.dataKey;
    this.sort.emit(sortParameters);
  }

  emitRowAction(row: any) {
    this.rowAction.emit(row);
  }

  emitViewAction(row: any){
    this.viewAction.emit(row)
  }

  emitEditAction(row: any) {
    this.editAction.emit(row)
  }

  emitDeleteAction(row: any) {
    this.deleteAction.emit(row)
  }

  emitExtraAction(row: any) {
    this.extraAction.emit(row)
  }

  emitDeactiveAction(row: any) {
    this.deactivateAction.emit(row)
  }

  emitActiveAction(row: any) {
    this.activateAction.emit(row)
  }

  showFirstSpecialButtons() {
    // event.stopPropagation();
    this.specialFirstButtonClick.emit();
  }

  showSecondSpecialButtons() {
    // event.stopPropagation();
    this.specialSecondButtonClick.emit();
  }

  dateFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0;
  }

  logStartDate() {
    console.log(this.dateRangeForm.value.startDate, 'startDate')
  }

  logEndDate() {
    console.log(this.dateRangeForm.value.endDate, 'endDate')
  }

  onshowStatus() {
  }

  getStatusClass(status: string): any {
    if (status && typeof status == 'string') {
      let newStatus = status.toLowerCase();
      switch (newStatus) {
        case 'processing':
          case 'no changes':
          return 'status-processing';
        case 'successful':
          return 'status-successful';
        case 'approved':
          return 'status-successful';
        case 'failed':
          return 'status-failed';
        case 'rejected':
          return 'status-failed';
        case 'pending':
          return 'status-pending';
        default:
          return ''; // Default class if status doesn't match any case
      }
    } else {
      switch (status) {
        case 'processing':
          return 'status-processing';
        case 'successful':
          return 'status-successful';
        case 'approved':
          return 'status-successful';
        case 'failed':
          return 'status-failed';
        case 'rejected':
          return 'status-failed';
        case 'pending':
          return 'status-pending';
        default:
          return ''; // Default class if status doesn't match any case
      }
    }
  }

  onRowClick(row: any, event: MouseEvent) {
    if (event.target && !(event.target as HTMLElement).closest('.table-icon, .mat-menu-item')) {
      // Emit the row click event to the parent component
      this.rowClick.emit(row);
    }
    // Prevent the event from propagating to the parent elements
    event.stopPropagation();
  }

  trackById(index: number, item: any): any {
    return item.id;
  }

  ngOnDestroy(): void {
    // this.themeSubscription?.unsubscribe();
    this.isLoggedInSubscription?.unsubscribe();
  }
}