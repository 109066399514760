import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { TokenService } from '../services/token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private tokenService: TokenService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenService.getToken();
    if (token) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      return this.tokenService.refreshTokenIfNeeded().pipe(
        switchMap((newToken: string) => {
          this.isRefreshing = false;
          this.tokenService.setToken(newToken);
          return next.handle(this.addToken(request, newToken));
        }),
        catchError((error: any) => {
          this.isRefreshing = false;
          return throwError(error);
        })
      );
    } else {
      return this.tokenService.refreshTokenIfNeeded().pipe(
        filter((token: any) => token != null),
        take(1),
        switchMap((jwt: string) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }
}
