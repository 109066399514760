export class MenuItem {
  title: string;
  hasSubmenu: boolean;
  submenuItems: MenuItem[];
  icon: string;
  onClickHandler?: () => void;

  constructor({
    title,
    hasSubmenu = false,
    submenuItems = [],
    icon = '',
    onClickHandler,
  }: {
    title: string;
    hasSubmenu?: boolean;
    submenuItems?: MenuItem[];
    icon?: string;
    onClickHandler?: () => void;
  }) {
    this.title = title;
    this.hasSubmenu = hasSubmenu;
    this.submenuItems = submenuItems;
    this.icon = icon;
    this.onClickHandler = onClickHandler ?? undefined;
  }
}

export enum MenuColors {
  main_background = '#0F3F50',
  main_active_background = '#175E77',
  primary_font_color = '#bcc7d9',
  active_font_color = '#ffff',
  inpage_main_backgound = '#f7faf9',
  inpage_active_background = '#dce0e1',
  inpage_primary_font_color = '#858887',
  inpage_active_font_color = '#272928',
}
