import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
// Template and UI Design Implementation by Iruðbe_Akhigbe_Ayðmídee

@Component({
  selector: 'app-primary-button',
  template: `
    <div
      class="button-container"
      (mouseenter)="toggleTooltip(true)"
      (mouseleave)="toggleTooltip(false)"
    >
      <button
        *ngIf="!iconButton"
        [type]="type"
        (click)="onClick()"
        [ngClass]="{ 'disabledButton': isDisabled, btn: !isDisabled }"
        [disabled]="isDisabled"
        class="btn"
      >
        <span [style.color]="color" [style.font-family]="fontFamily">
          {{ buttonText }}
        </span>
      </button>

      <button
        *ngIf="iconButton"
        mat-icon-button
        [type]="type"
        (click)="onClick()"
        [ngClass]="{'disabledButton': isDisabled, 'icon-btn': !isDisabled}"
        [disabled]="isDisabled"
        class="icon-btn {{iconBtnClass}}"
      >
        <mat-icon matPrefix *ngIf="matIconSvg && iconBeginning" svgIcon="matPrefixSvg">
        </mat-icon>
        <span *ngIf="matIconText"
          [style.color]="color"
          [style.font-family]="fontFamily">
            {{ buttonText }}
        </span>
        <mat-icon matSuffix *ngIf="matIconSvg && iconEnd" svgIcon="matSuffixSvg">
        </mat-icon>
      </button>
      <app-tooltip
        *ngIf="!hideToolTip"
        [text]="tooltipText"
        [show]="showTooltip"
      ></app-tooltip>
    </div>
  `,
  styleUrls: ['./primary-button.component.css'],
})
export class PrimaryButtonComponent implements OnInit, OnChanges {
  @Input()
  set text(name: string) {
    this.buttonText = name;
  }
  get text(): string {
    return this.buttonText;
  }

  @Input() iconBtnClass: string = "";
  @Input() matIconText: boolean = true;
  @Input() matIconSvg: boolean = false;
  @Input() matPrefixSvgValue!: string;
  @Input() matSuffixSvgValue!: string;
  @Input() color: string = '';
  @Input() fontSize: string = '';
  @Input() fontFamily: string = '';
  @Input() iconButton = false;
  @Input() iconBeginning = false;
  @Input() iconEnd = false;
  @Input() type: string = 'button';
  @Output() btnClick = new EventEmitter();
  @Input() isDisabled: boolean = false;
  @Input() hideToolTip: boolean = false; 
  @Input() tooltipText: string = ''; 

  public buttonText = '';
  public showTooltip = false;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['matPrefixSvgValue'] && this.matPrefixSvgValue) {
      this.matIconRegistry.addSvgIcon(
        'matPrefixSvg',
        this.domSanitizer.bypassSecurityTrustResourceUrl(this.matPrefixSvgValue)
      );
    }
    if (changes['matSuffixSvgValue'] && this.matSuffixSvgValue) {
      this.matIconRegistry.addSvgIcon(
        'matSuffixSvg',
        this.domSanitizer.bypassSecurityTrustResourceUrl(this.matSuffixSvgValue)
      );
    }
  }  

  onClick() {
    this.btnClick.emit();
  }

  toggleTooltip(show: boolean) {
    this.showTooltip = show;
  }
}
