import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../api/authentication/authentication.service';
import { CacheService } from '../../cache/cache.service';
import {Select} from "@ngxs/store";
import { AppState } from 'src/app/store/app.state';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  @Select(AppState.getToken) token$!: Observable<string>;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private cacheService: CacheService
    )
  { }

  // canMatch(): boolean {
  //   this.token$.subscribe((token: any) => {
  //     if (token) {
  //       return true
  //     } else {
  //       this.router.navigate(['/auth/login']).then();
  //       return false
  //     }
  //   });
  //   return true;
  // }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>((observer) => {
      this.token$.subscribe((token: any) => {
        if (token) {
          observer.next(true);
          observer.complete();
        } else {
          this.router.navigate(['/auth/login']);
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

}
