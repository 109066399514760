import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
// Template and UI Design Implementation by Iruðbe_Akhigbe_Ayðmíde

@Component({
  selector: 'app-stroked-button',
  template : `
    <div
      class="button-container"
      (mouseenter)="toggleTooltip(true)"
      (mouseleave)="toggleTooltip(false)"
    >
      <button
        *ngIf="!iconButton"
        [type]="type"
        (click)="onClick()"
        [ngClass]="{'disabledButton': isDisabled, 'btn': !isDisabled}"
        [disabled]="isDisabled"
        class="btn">
        <span
          [style.color]="color"
          [style.font-family]="fontFamily">
            {{ buttonText }}
        </span>
      </button>

      <button
        *ngIf="iconButton"
        mat-icon-button
        [type]="type"
        (click)="onClick()"
        [ngClass]="{'disabledButton': isDisabled, 'icon-btn': !isDisabled}"
        [disabled]="isDisabled"
        class="icon-btn"
      >
      <mat-icon matPrefix *ngIf="matIconSvg && iconBeginning" svgIcon="strokedPrefixSvg">
        </mat-icon>
        <span *ngIf="matIconText"
          [style.color]="color"
          [style.font-family]="fontFamily">
            {{ buttonText }}
        </span>
        <mat-icon matSuffix *ngIf="matIconSvg && iconEnd" svgIcon="strokedSuffixSvg">
        </mat-icon>
      </button>
      <app-tooltip
        *ngIf="!hideToolTip"
        [text]="tooltipText"
        [show]="showTooltip"
      ></app-tooltip>
    </div>
  `,
  styleUrls: ['./stroked-button.component.css']
})
export class StrokedButtonComponent implements OnInit {
  @Input()
	set text(name: string) {
		this.buttonText = name;
	}
	get name(): string {
		return this.buttonText;
	}

  @Input() matIconText: boolean = true;
  @Input() matIconSvg: boolean = false;

  @Input() strokedPrefixSvg!: string;
  @Input() strokedSuffixSvg!: string;
  
  @Input() matIconTextValue!: string;
	@Input() svgSrc: string = '';
	@Input() color: string = '';
	// @Input() color: string = 'rgb(231, 0, 0, 0.8)';
	@Input() fontSize: string = '';
	@Input() fontFamily: string = '';
  @Input() iconButton = false;
	@Input() type: string = 'button';
  @Input() iconBeginning = false;
  @Input() iconEnd = false;
	@Input() buttonIcon: string = '';
  @Output() btnClick = new EventEmitter();
	@Input() isDisabled: boolean = false;
  @Input() hideToolTip: boolean = false; 
  @Input() tooltipText: string = '';

	public buttonText = '';
  public showTooltip = false;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['strokedPrefixSvg'] && this.strokedPrefixSvg) {
      this.matIconRegistry.addSvgIcon(
        'strokedPrefixSvg',
        this.domSanitizer.bypassSecurityTrustResourceUrl(this.strokedPrefixSvg)
      );
    }
    if (changes['strokedSuffixSvg'] && this.strokedSuffixSvg) {
      this.matIconRegistry.addSvgIcon(
        'strokedSuffixSvg',
        this.domSanitizer.bypassSecurityTrustResourceUrl(this.strokedSuffixSvg)
      );
    }
  }  

  onClick() {
    this.btnClick.emit();
  }

  toggleTooltip(show: boolean) {
    this.showTooltip = show;
  }
}
