import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-row-item',
  templateUrl: './row-item.component.html',
  styleUrls: ['./row-item.component.css'],
})
export class RowItemComponent implements OnInit {
  @Input() icon?: string = '';
  @Input() title?: string = '';
  @Input() subtitle?: string = '';
  @Input() isClickable?: boolean = false;
  @Input() onClickHandler?: () => void = undefined;

  hasSubtitle: boolean = false;

  onClick() {
    if (this.onClickHandler != undefined) {
      this.onClickHandler();
    }
    console.log('Row clicked ', this.title);
  }

  checkSubtitle() {
    if (this.subtitle == '') {
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    this.hasSubtitle = this.checkSubtitle();
  }
}
