export class SetIsAppLoaded {
    static readonly type = '[App] SetIsAppLoaded';
    constructor(public isAppLoaded: boolean) {}
}

export class SetUserProfile {
    static readonly type = '[App] SetUserProfile';
    constructor(public userProfile: any) {}
}

export class SetToken {
    static readonly type = '[App] SetToken';
    constructor(public token: string) {}
}

export class SetRefreshToken {
    static readonly type = '[App] SetRefreshToken';
    constructor(public refreshToken: string) {}
}

export class SetGmailInitState {
  static readonly type = '[App] SetGmailInitState';
  constructor(public gmailInit: boolean) {}
}