import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  TemplateRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { AuthenticationService } from 'src/app/core/api/authentication/authentication.service';
import { BootstrapNotifyService } from 'src/app/core/services/bootstrapNotify/bootstrap-notify.service';
import { Select, Store } from '@ngxs/store';
import { AppState } from '../../../store/app.state';
import { User } from '../../models/user-model';
import { MenuItem } from './menu-item/menu-item-model';
import { MenuService } from 'src/app/core/services/menu-service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SubscriptionManagementDirective } from 'src/app/core/directives/subscription-management.directive';

/* 
  Usage: 
    <app-side-bar [isCollapsed]="<initial state>" [sidenavposition]="<side nav bar position>", [contentTemplate]="<page template>"></app-side-bar>
  Inputs:
    - isCollapsed: Boolean value to set the initial state of the nav bar. Default value is false
    - sidenavposition: Location of the side nav bar. Possible values are `start` or `end` Default value is `start` Set to `end` to place on the right side of the screen
    - contentTemplate: ng template to display in the main content section of the MatSideNavContainer. 
      All pages of the web app that have the nav bar must pass in a template to integrate with the side bar
 */

@Component({
  selector: 'app-side-bar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  providers: [MenuService],
})
export class SideBarComponent extends SubscriptionManagementDirective implements OnInit, OnDestroy {
  user!: User;
  iconBasePath = '../../assets/icons/sidebar/';
  logoIcon = this.iconBasePath + 'logo.svg';
  collapseToggle!: string;

  main_sidebar_items: MenuItem[] = [
    new MenuItem({
      title: 'CoStaff AI',
      icon: this.iconSrc('home-icon.svg'),
      onClickHandler: this.navigateTo('home'),
    }),
    new MenuItem({
      title: 'Email',
      icon: this.iconSrc('email-icon.svg'),
      onClickHandler: this.navigateTo('email'),
    }),
    new MenuItem({
      title: 'Calendar',
      icon: this.iconSrc('calendar-icon.svg'),
      onClickHandler: this.navigateTo('calendar'),
    }),
    new MenuItem({
      title: 'Invoice',
      icon: this.iconSrc('financials-icon.svg'),
      onClickHandler: this.navigateTo('invoice'),
    }),
    new MenuItem({
      title: 'Inventory',
      icon: this.iconSrc('bookings-icon.svg'),
      onClickHandler: this.navigateTo('inventory'),
    }),
    new MenuItem({
      title: 'Analytics',
      icon: this.iconSrc('analytics-icon.svg'),
      onClickHandler: this.navigateTo('analytics'),
    }),
    new MenuItem({
      title: 'Documents',
      icon: this.iconSrc('documents-icon.svg'),
      onClickHandler: this.navigateTo('documents'),
    }),
    new MenuItem({
      title: 'Tasks',
      icon: this.iconSrc('tasks-icon.svg'),
      onClickHandler: this.navigateTo('tasks'),
    }),
    new MenuItem({
      title: 'Bookings',
      icon: this.iconSrc('financials-icon.svg'),
      onClickHandler: this.navigateTo('bookings'),
    }),
  ];

  @Select(AppState.getUserProfile) $userProfile!: Observable<User>;
  @Input() isCollapsed: boolean = false;
  @Input() sidenavposition: 'start' | 'end' = 'start';
  @Input() contentTemplate!: TemplateRef<any>;
  @Output() collapseToggled = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public menuService: MenuService
  ) {
    super();
    this.matIconRegistry.addSvgIcon(
      'logo-with-text',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        this.iconBasePath + 'logo-with-text-icon.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        this.iconBasePath + 'logo.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'collapse',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        this.iconBasePath + 'collapse-icon.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'expand',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        this.iconBasePath + 'expand-icon.svg'
      )
    );
  }

  navigateTo(route: string) {
    return () => {
      this.router.navigateByUrl('main/' + route);
    };
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;

    this.collapseToggled.emit(this.isCollapsed);
  }

  iconSrc(iconName: string): string {
    return this.iconBasePath + iconName;
  }

  ngOnInit(): void {
    this.$userProfile.pipe(takeUntil(this.unSubscribe)).subscribe((data: any) => {
      if (data != undefined) {
        this.user = data;
      }
    });
  }

  // logOut() {
  //   this.authService.logOut();
  // }

}
