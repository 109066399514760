import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  SetIsAppLoaded,
  SetRefreshToken,
  SetToken,
  SetUserProfile,
  SetGmailInitState
} from './app.action';
import { User } from '../shared/models/user-model';

export class AppStateModel {
  isAppLoaded: boolean | undefined;
  userProfile: User | undefined;
  token: string | undefined;
  gmailInit: boolean | undefined;
  refreshToken: string | undefined;
}

@Injectable()
@State<AppStateModel>({
  name: 'App',
  defaults: {
    isAppLoaded: false,
    userProfile: undefined,
    token: '',
    refreshToken: '',
    gmailInit: false,
  },
})
export class AppState {
  @Selector()
  static getIsAppLoaded(state: AppStateModel): boolean {
    return <boolean>state.isAppLoaded;
  }

  @Selector()
  static getUserProfile(state: AppStateModel): any {
    return state.userProfile;
  }

  @Selector()
  static getToken(state: AppStateModel): string {
    return <string>state.token;
  }

  @Selector()
  static getRefreshToken(state: AppStateModel): string {
    return <string>state.refreshToken;
  }

  @Selector()
  static getGmailInitState(state: AppStateModel): boolean {
    return <boolean>state.gmailInit;
  }

  // actions
  @Action(SetUserProfile)
  setUserProfile(
    { getState, setState }: StateContext<AppStateModel>,
    { userProfile }: SetUserProfile
  ): void {
    const state = getState();
    setState({
      ...state,
      userProfile,
    });
  }
  @Action(SetIsAppLoaded)
  setIsAppLoaded(
    { getState, setState }: StateContext<AppStateModel>,
    { isAppLoaded }: SetIsAppLoaded
  ): void {
    const state = getState();
    setState({
      ...state,
      isAppLoaded,
    });
  }

  @Action(SetToken)
  setToken(
    { getState, setState }: StateContext<AppStateModel>,
    { token }: SetToken
  ): void {
    const state = getState();
    setState({
      ...state,
      token,
    });
  }

  @Action(SetRefreshToken)
  setRefreshToken(
    { getState, setState }: StateContext<AppStateModel>,
    { refreshToken }: SetRefreshToken
  ): void {
    const state = getState();
    setState({
      ...state,
      refreshToken,
    });
  }

  @Action(SetGmailInitState)
  SetGmailInitState({ getState, setState }: StateContext<AppStateModel>, { gmailInit }: SetGmailInitState): void {
    const state = getState();
    setState({
      ...state,
      gmailInit,
    });
  }
}
