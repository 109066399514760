import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { Select } from '@ngxs/store';
import { AppState } from 'src/app/store/app.state';
import { User } from 'src/app/shared/models/user-model';
import { SubscriptionManagementDirective } from 'src/app/core/directives/subscription-management.directive';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.css'],
})
export class ProfileHeaderComponent extends SubscriptionManagementDirective implements OnInit {
  @Select(AppState.getUserProfile) $userProfile!: Observable<any>;
  user!: User;
  profileImage!: string;

  constructor() { super(); }

  ngOnInit(): void {
    this.$userProfile.pipe(takeUntil(this.unSubscribe)).subscribe((data: any) => {
      this.user = data;
      this.profileImage = '../../assets/images/sample_profile_image.jpeg';
    });
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.profileImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  userName() {
    return this.user.firstName + ' ' + this.user.lastName;
  }

  userEmail() {
    return this.user.email;
  }
}
