import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-primary-link',
  template: `
      <a routerLink="{{routerLink}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
         <span>
            {{routerLinkName}}
         </span>
      </a>
   `,
  styleUrls: ['../../../styles/primary-link.css']
})
export class PrimaryLinkComponent implements OnInit {
   @Input() routerLink: string = '';
   @Input() routerLinkName: string = ''; 
   
   constructor() {}

   ngOnInit(): void {}

   
}
