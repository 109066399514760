import { Component, HostBinding, HostListener } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})

export class LandingComponent {
    isDesktop: boolean = true;

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.checkScreenSize();
    }
  
    ngOnInit() {
        this.checkScreenSize();
    }

    private checkScreenSize() {
        this.isDesktop = window.innerWidth >= 810;
        console.log('Is Desktop:', this.isDesktop, 'Width:', window.innerWidth);
    }

    routeLink(route: string) {

    }

    isMenuOpen: boolean = false;

    toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}