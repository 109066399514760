<div class="overlay" (click)="onTap()"></div>
<div class="menu-container">
  <div>
    <app-profile-header></app-profile-header>
  </div>
  <div class="settings-nav-bar">
    <app-row-item
      *ngFor="let item of settings_menu_items"
      [icon]="item.icon"
      [title]="item.title"
      [isClickable]="true"
      [onClickHandler]="item.onClickHandler"
    ></app-row-item>
  </div>
  <div class="account-list">
    <app-row-item
      *ngFor="let item of accounts"
      [icon]="item.icon"
      [title]="item.title"
      [subtitle]="item.subtitle"
    ></app-row-item>
  </div>
  <div class="menu-footer">
    <app-row-item
      [icon]="logout_menu_item.icon"
      [title]="logout_menu_item.title"
      [isClickable]="true"
      [onClickHandler]="logout_menu_item.onClickHandler"
    ></app-row-item>
  </div>
</div>