import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {
  private currencyMap: { [key: string]: string } = {
    'EUR': '€',
    'GBP': '£',
    'USD': '$',
    'SAR': '﷼',
    'AED': 'د.إ',
    // Add more currencies as needed
  };

  transform(currencyCode: string): string {
    return this.currencyMap[currencyCode] || currencyCode;
  }
}