import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header-card',
  template: `
    <mat-card [ngClass]="{'showImgHeaderCard': !showCardAction, 'showBtnHeaderCard': showCardAction}">
      <div class="card-div">
        <img class="setup-svg" src={{CardSvg}} alt="">
        <span class="setup-name">{{CardName}}</span>
        <div class="actionBtnDiv" *ngIf="showCardAction">
          <button mat-flat-button color="warn" (click)="emitActionBtn()">
            {{CardActionText}}
          </button>
        </div>
      </div>
    </mat-card>

  `,
  styleUrls: ['../../../../styles/card-reuse.css']
})

export class HeaderCardComponent implements OnInit {
  @Input() CardSvg!: string;
  @Input() CardName!: string;
  @Input() CardActionText!: string;
  @Input() showCardAction = false;

  @Output() CardAction: EventEmitter<any> = new EventEmitter<any>();

  constructor() {

  }

  ngOnInit(): void {

  }

  emitActionBtn(){
    this.CardAction.emit();
  }

}
