import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '../../../../../../store/app.state';
import { User } from '../../../../../models/user-model';
import { SubscriptionManagementDirective } from 'src/app/core/directives/subscription-management.directive';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-profile-footer',
  templateUrl: './profile-footer.component.html',
  styleUrls: ['./profile-footer.component.css'],
})
export class ProfileFooterComponent extends SubscriptionManagementDirective {
  activeProfileMenuIcon = '../../assets/icons/sidebar/chevron-up.svg';
  profileMenuIcon = '../../assets/icons/sidebar/chevron-down.svg';
  isActive = false;
  userName!: string;

  @Input() imageUrl?: string = '';
  @Input() isCollapsed: boolean = false;
  @Output() profileMenuVisibilityEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(private store: Store) {
    super();
    this.store.select(AppState.getUserProfile).pipe(takeUntil(this.unSubscribe)).subscribe((user: User) => {
      this.userName = user.firstName + ' ' + user.lastName;
    });
  }

  toggleProfileMenu() {
    this.isActive = !this.isActive;
    if (this.isActive) {
      this.profileMenuIcon = this.activeProfileMenuIcon;
    } else {
      this.profileMenuIcon = '../../assets/icons/sidebar/chevron-down.svg';
    }
    this.profileMenuVisibilityEvent.emit(this.isActive);
  }

  onProfileMenuTapListener(event: boolean) {
    if (event == true) {
      this.toggleProfileMenu();
    }
  }
}
