import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-modal',
  template: `
    <div class="modal-wrapper">
        <div class="modal-content">
            <div class="title">
                <h3 class="modalTitle">
                {{modalHeaderTitle}}
                </h3>
            </div>
            <div class="title">
                <span class="modalMessage">
                {{modalMessage}}
                </span>
                <span class="modalMessage1">
                {{modalSecondMessage}}
                </span>
            </div>

            <div class="invoice_logo margin1rem" (click)="uploadImage()">
                <img src="assets/icons/invoice/create-logo-icon.svg" alt="">
                <div class="invoice_logo_text">
                    <span> {{uploadText}} </span>
                    <span> JPG, JPEG, PNG, less than 5MB </span>
                </div>
            </div>
        </div>
        <div class="modalFooter" *ngIf="!twoButtonAction">
            <br>
            <button class="footerbtn" (click)="action()">
            {{actionBtnText}}
            </button>
        </div>
        <div class="modalFooter footer-grid" *ngIf="twoButtonAction">
            <div style="display: flex; justify-content: end">
            <app-flat-button
                [type]="'button'"
                text="{{actionBtnText}}"
                fontSize="0.9rem"
                [isDisabled]=false
                (btnClick)="action()"
            >
            </app-flat-button>
            </div>
            <div style="display: flex; justify-content: start">
            <app-primary-button
                [type]="'button'" 
                [iconButton]="false"
                [hideToolTip]="true"
                text="{{closeBtnText}}"
                fontFamily="SoraMedium"
                fontSize="0.92rem"
                [isDisabled]="false"
                (btnClick)="closeModal()"
            >
            </app-primary-button>
            </div>
        </div>
    </div>

  `,
  styleUrls: ['../../../../styles/upload-modal.css']
})

export class UploadModalComponent implements OnInit {

  @Input() modalIcon!: string;
  @Input() goodStatus = false;
  @Input() warningStatus = false;
  @Input() twoButtonAction = false;
  @Input() uploadText!: string;
  @Input() modalHeaderTitle!: string;
  @Input() modalMessage!: string;
  @Input() modalSecondMessage!: string;
  @Input() actionBtnText!: string;
  @Input() closeBtnText!: string;
  @Input() tooltipText: string = ''; // Tooltip text
  public showTooltip = false;

  @Output() modalAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeModalAction: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog)
  { }


  ngOnInit(): void {
  }

  uploadImage() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', '*/*');
    input.click();
  
    input.onchange = () => {
      const file = input.files ? input.files[0] : null;
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
        };
        reader.readAsDataURL(file);
      }
    }
  }

  action(): void {
    this.modalAction.emit();
  }

  closeModal(): void {
    this.closeModalAction.emit();
  }

}
