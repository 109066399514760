import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  template: `
      <div class="tooltip-container" [ngClass]="{ 'show-tooltip': show }">
         <span>
            {{ text }}
         </span>
      </div>
   `,
  styleUrls: ['../../../styles/tooltip.css']
})
export class ToolTipComponent implements OnInit {
   @Input() text: string = '';
   @Input() show: boolean = false;   
   
   constructor() {}

   ngOnInit(): void {}

   
}
