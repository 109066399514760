import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-radio',
  template: `
      <mat-radio-group class="mat-radio-group" (change)="selectedRadio($event.value)">
         <mat-radio-button disableRipple=true *ngFor="let option of options" [value]="option.value">
            <span class="radio-button-span">
               {{ option.label }}
            </span>
         </mat-radio-button>
      </mat-radio-group>
   `,
  styleUrls: ['../../../styles/radio.css']
})
export class RadioButtonComponent implements OnInit {

   @Input() options!: { label: string, value: any }[];
   @Output() selectedRadioElement = new EventEmitter<any>();
   
   constructor() {}

   ngOnInit(): void {}

   selectedRadio(value: any) {
      this.selectedRadioElement.emit(value);
   }
}
