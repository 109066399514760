<div class="profile-header">
  <div class="profile-image-container">
    <!-- src="../../assets/images/sample_profile_image.jpeg" -->
    <img
      src="../../assets/icons/sidebar/logo.svg"
      alt="Profile Image"
      class="profile-image"
    />
    <img
      src="../../assets/icons/sidebar/edit-profile.svg"
      alt="Edit Icon"
      class="edit-icon"
      (click)="onFileSelected($event)"
    />
  </div>
  <div class="profile-details">
    <h2 class="user-name">{{ userName() }}</h2>
    <span class="user-email">{{ userEmail() }}</span>
  </div>
</div>
