import { Injectable } from '@angular/core';
import $ from 'jquery';
import 'bootstrap-notify';

@Injectable({
  providedIn: 'root'
})
export class BootstrapNotifyService {
  private options: any = {
    type: null,
    delay: 5000,
    z_index: 999999999999999999,
    showProgressbar: true,
    allow_dismiss: true,
    positon: 'bottom-right',
    placement: {
      align: 'bottom-right',
    },
    template: this.getTemplate()
  };

  constructor() {}

  private getTemplate(): string {
    return `
      <div data-notify="container" class="col-xs-11 notification col-sm-3 alert alert-{0} message-block" role="alert">
        <span data-notify="title" class="error-message">{1}</span>
        <span data-notify="message" class="error-message">{2}</span>
        <button type="button" aria-hidden="true" class="close" data-notify="dismiss" style="cursor: pointer; margin-top: 5px; font-size:1.5rem; border-radius: 6px; display: flex; align-items: center; width: auto; justify-content: center; height: 1.5rem; padding-top: 3px;">
          <span style="display: flex; align-items:center; justify-content: center;">&times;</span>
        </button>
      </div>`;
  }

  private notify(message: string, title: string, type: string, delay: number) {
    $('div.notification').remove();
    $.notify({
      title: title,
      message: message
    },{
      type: type,
      delay: delay,
      placement: {
        from: "bottom",
        align: "right"
      },
      template: this.options.template
    });
  }

  public custom(type = 'danger', title = 'Notification', msg: any) {
    const notify = $.notify({ message: '' }, this.options);
    notify.update('type', type);
    notify.update('title', `&nbsp;&nbsp;<strong>${title}</strong>`);
    notify.update('message', `<p>&nbsp;${msg}</p>`);
    notify.close();
  }

  public success(msg: string, delay = 500) {
    this.notify(msg, 'Success!!', 'pastel-success', delay);
  }

  public specialSuccess(msg: string, delay = 15000) {
    this.notify(msg, 'Success!', 'pastel-success', delay);
  }

  public error(msg: string, delay = 100) {
    this.notify(msg, 'Error!', 'pastel-danger', delay);
  }

  public specialError(msg: string, delay = 500) {
    this.notify(msg, 'Error!', 'pastel-danger', delay);
  }

  public info(msg: string, delay = 1000) {
    this.notify(msg, 'Info!', 'pastel-info', delay);
  }

  public specialInfo(msg: string, delay = 5000000) {
    this.notify(msg, 'Info!', 'pastel-info', delay);
  }

  public warning(msg: string, delay = 8000) {
    this.notify(msg, 'Warning!', 'pastel-warning', delay);
  }
}
