import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// Template and UI Design Implementation by Iruðbe_Akhigbe_Ayðmíde
@Component({
  selector: 'app-caution-button',
  template : `
    <button
      *ngIf="!iconButton"
      [type]="type"
      (click)="onClick()"
      [ngClass]="{'disabledButton': isDisabled, 'btn': !isDisabled}"
      [disabled]="isDisabled"
      class="btn">
      <span
        [style.color]="color"
        [style.font-family]="fontFamily">
          {{ buttonText }}
      </span>
    </button>

    <button
      *ngIf="iconButton"
      mat-icon-button
      [type]="type"
      (click)="onClick()"
      [ngClass]="{'disabledButton': isDisabled, 'enabledBtn': !isDisabled}"
      [disabled]="isDisabled"
      class="icon-btn">
      <mat-icon *ngIf="matIconText">
        {{matIconTextValue}}
      </mat-icon>
      <mat-icon *ngIf="matIconSvg" matPrefix svgIcon="mailIcon">        
      </mat-icon>
    </button>
  `,
  styleUrls: ['./caution-button.component.css']
})
export class CautionButtonComponent implements OnInit {
  @Input()
	set text(name: string) {
		this.buttonText = name;
	}
	get name(): string {
		return this.buttonText;
	}

  @Input() matIconText: boolean = false;
  @Input() matIconSvg: boolean = false;
  @Input() matIconTextValue!: string;
	@Input() color: string = '';
	// @Input() color: string = 'rgb(231, 0, 0, 0.8)';
	@Input() fontSize: string = '';
	@Input() fontFamily: string = '';
  @Input() iconButton = false;
	@Input() type: string = 'button';
	@Input() buttonIcon: string = '';
  @Output() btnClick = new EventEmitter();
	@Input() isDisabled: boolean = false;

	public buttonText = '';
  constructor() { }

  ngOnInit() {
  }

  onClick() {
		this.btnClick.emit();
	}

}
