import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs';
import { SubscriptionManagementDirective } from 'src/app/core/directives/subscription-management.directive';
import { MenuService } from 'src/app/core/services/menu-service';
import { MenuColors, MenuItem } from './menu-item-model';

@Component({
  selector: 'app-menu-item',
  template: ` 
    <div
      class="app-menu-item"
      [ngClass]="{ itemSubmenu: isSubMenuActive }"
    >
      <div class="menu-item-button">
        <button
          class="custom-menu-item"
          [ngClass]="{
            active: isActive,
            collapsed: isCollapsed
          }"
          [ngStyle]="{
            'background-color': isActive ? activeColor : 'transparent'
          }"
          (click)="handleClick()"
        >
          <img *ngIf="menuItem.icon" [src]="menuItem.icon" alt="{{ menuItem.title }} Home icon" />
          <span
            [ngStyle]="{ color: isActive ? activeFontColor : primaryFontColor }"
            >{{ menuItem.title }}</span
          >
          <img
            *ngIf="menuItem.hasSubmenu"
            [src]="subMenuIcon"
            alt="Submenu icon"
            (click)="handleSubMenuClick()"
          />
        </button>
        <mat-divider
          [ngStyle]="{ visibility: showDivider ? 'visible' : 'hidden' }"
          class="active-divider"
          [vertical]="true"
        ></mat-divider>
      </div>
      <app-nav-bar
        *ngIf="isSubMenuActive"
        [ngClass]="{ itemSubmenu: isSubMenuActive }"
        [backgroundColor]="menuColors.inpage_main_backgound"
        [activeItemColor]="menuColors.inpage_active_background"
        [primaryFontColor]="menuColors.inpage_primary_font_color"
        [activeFontColor]="menuColors.inpage_active_font_color"
        [isSubmenu]="true"
        [menuItems]="submenuItems"
        (menuItemClicked)="handleClick()"
      ></app-nav-bar>
    </div>
  `,
  styleUrls: ['./menu-item.component.css'],
})
export class MenuItemComponent extends SubscriptionManagementDirective implements OnInit, OnDestroy {
  isActive!: boolean;
  inActiveSubMenuIcon = '';
  activeSubMenuIcon = '';
  subMenuIcon!: string;

  allowedItems: string[] = [
    "Inbox",
    "Primary",
    "Promotions",
    "Updates",
    "Important"
  ];

  isSubMenuActive = false;
  submenuItems!: MenuItem[];
  menuColors = MenuColors;

  @Input() menuItem!: MenuItem;
  @Input() isCollapsed: boolean = false;
  @Input() primaryFontColor: string = MenuColors.primary_font_color;
  @Input() activeFontColor: string = MenuColors.active_font_color;
  @Input() activeColor?: string;
  @Input() subMenuIconColor: string = 'white';
  @Output() menuItemClicked = new EventEmitter<string>();

  constructor(private menuService: MenuService) {
    super();
  }

  handleClick(): void {
    console.log('MenuItem clicked:', this.menuItem.title);

    if (this.menuItem.onClickHandler) {
      this.menuItem.onClickHandler();
    }

    // Check if the current item is within the allowedItems array
    if (this.allowedItems.includes(this.menuItem.title)) {
      console.log('Allowed item clicked, handle submenu activation');
      this.isSubMenuActive = this.menuItem.hasSubmenu && this.isSubMenuActive;
    } else {
      console.log('Non-allowed item clicked, deactivate submenu');
      this.isSubMenuActive = false;
    } 
    this.subMenuIcon = this.isSubMenuActive ? this.activeSubMenuIcon : this.inActiveSubMenuIcon;

    this.menuService.setActiveItem(this.menuItem.title.toLowerCase());
    this.menuItemClicked.emit(this.menuItem.title);
  }

  handleSubMenuClick(): void {
    this.isSubMenuActive = !this.isSubMenuActive;
    this.subMenuIcon = this.isSubMenuActive
      ? this.activeSubMenuIcon
      : this.inActiveSubMenuIcon;
  }

  toPascalCase(input: string): string {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  get showDivider(): boolean {
    return this.isActive && !this.menuItem.hasSubmenu;
  }

  setSubMenuIcon(): void {
    switch (this.subMenuIconColor) {
      case 'white': {
        this.inActiveSubMenuIcon =
          '../../assets/icons/sidebar/chevron-down.svg';
        this.activeSubMenuIcon = '../../assets/icons/sidebar/chevron-up.svg';
        break;
      }
      case 'dark': {
        this.inActiveSubMenuIcon =
          '../../assets/icons/sidebar/chevron-down-dark.svg';
        this.activeSubMenuIcon =
          '../../assets/icons/sidebar/chevron-up-dark.svg';
        break;
      }
    }
    this.subMenuIcon = this.inActiveSubMenuIcon;
  }

  openSubmenu(): void {}

  closeSubmenu(): void {}

  ngOnInit(): void {
    if (this.menuItem.hasSubmenu) {
      this.setSubMenuIcon();
      this.submenuItems =
        this.menuItem.submenuItems !== undefined
          ? this.menuItem.submenuItems
          : [];
    }
    // this.isActive = false;
    this.isActive = this.menuItem.title === 'Inbox';
    if (this.isActive) {
      this.menuService.setActiveItem(this.menuItem.title.toLowerCase());
      this.isSubMenuActive = false;
        this.subMenuIcon = this.isSubMenuActive
          ? this.activeSubMenuIcon
          : this.inActiveSubMenuIcon;
    }

    this.menuService.getActiveItem().pipe(takeUntil(this.unSubscribe)).subscribe((activeId) => {
      activeId = activeId == 'CoStaff AI' ? activeId.toLowerCase() : activeId;
      this.isActive = activeId == this.menuItem.title.toLowerCase();
    });
  }

}
