<div
  [style.background-color]="backgroundColor"
  [class.collapsed]="isCollapsed"
  [class.submenu]="isSubmenu"
  class="nav-container"
>
  <mat-divider
    *ngIf="isSubmenu"
    class="submenu-divider"
    [vertical]="true"
  ></mat-divider>

  <div class="menu-items-container">
    <app-menu-item
      *ngFor="let item of menuItems"
      [menuItem]="item"
      [isCollapsed]="isCollapsed"
      [activeColor]="activeItemColor"
      [primaryFontColor]="primaryFontColor"
      [activeFontColor]="activeFontColor"
      [subMenuIconColor]="subMenuIconColor"
      (menuItemClicked)="getMenuItemClick($event)"
    >
    </app-menu-item>
  </div>
</div>
