import { Component, Input, OnInit } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';

@Component({
  selector: 'app-progress-bar',
  template: `
      <mat-progress-bar class="{{progress_bar_class}}" [mode]="modeType" [value]="value">
      </mat-progress-bar>
  `,
  styleUrls: ['../../../styles/progress-bar.css']
})


export class ProgressBarComponent implements OnInit {
   @Input() progress_bar_class: any;
   @Input() modeType: ProgressBarMode = 'determinate';
   @Input() value!: number;

   constructor() {
   }
   ngOnInit(): void {
   }
}
