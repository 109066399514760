import { BehaviorSubject } from 'rxjs';

export class MenuService {
  private activeItem = new BehaviorSubject<string | null>(null);

  setActiveItem(itemId: string) {
    this.activeItem.next(itemId);
  }

  getActiveItem() {
    return this.activeItem.asObservable();
  }

  seeActiveItem() {
    return this.activeItem;
  }
}
