import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-toggle',
  template: `
   <label class="switch">
      <input type="checkbox" [checked]="checked" (change)="onToggle($event)">
      <span class="slider round">
         <img *ngIf="checked" src="assets/icons/check.svg" alt="">
      </span>
   </label>
  `,
  styleUrls: ['../../../styles/button-toggle.css']
})


export class ButtonToggleComponent implements OnInit {
   @Input() checked: boolean = false;
   @Output() checkedChange = new EventEmitter<boolean>();

   constructor() {
   }
   ngOnInit(): void {
   }

   onToggle(event: Event): void {
     const inputElement = event.target as HTMLInputElement;
     this.checked = inputElement.checked;
     this.checkedChange.emit(this.checked);
   }
}
