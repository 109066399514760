import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth-guard/auth-guard.service';
import { LandingComponent } from './pages/landing/landing.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('src/app/pages/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'main',
    loadChildren: () =>
      import('src/app/pages/main/main.module').then((m) => m.MainModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'email',
    loadChildren: () =>
      import('src/app/pages/email/email.module').then((m) => m.EmailModule),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

// import { NgModule } from '@angular/core';
// import { RouterModule, Routes } from '@angular/router';
// import { AuthGuard } from './core/guards/auth-guard/auth-guard.service';
// import { LandingComponent } from './pages/landing/landing.component';

// const routes: Routes = [
//   {
//     path: '',
//     redirectTo: 'auth',
//     pathMatch: 'full',
//   },
//   {
//     path: '',
//     component: LandingComponent
//   },
//   {
//     path: 'auth',
//     loadChildren: () => import('src/app/pages/public/public.module').then(m => m.PublicModule)
//   },
//   {
//     path: 'main',
//     loadChildren: () => import('src/app/pages/main/main.module').then(m => m.MainModule),
//     canActivate: [AuthGuard]
//   },
//   {
//     path: 'email',
//     loadChildren: () => import('src/app/pages/email/email.module').then(m => m.EmailModule)
//   },
//   {
//     path: '**',
//     redirectTo: 'auth',
//     pathMatch: 'full',
//   }
// ];

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }
