import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from './core/services/loader.service';
import { Store } from "@ngxs/store";
import { SetIsAppLoaded } from "./store/app.action";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'CoStaff-Dev';

  constructor(
    public loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    const cssRule = 
    'color: rgba(46, 189, 239, 1);' +
    'font-size: 17px;' +
    'text-align: center;' +
    'font-weight: bold;' +
    'text-shadow: 1px 1px 5px rgba(46, 189, 239, 1));' +
    'filter: dropshadow(color=rgb(249, 162, 34), offx=1, offy=1);';

    setTimeout(console.info.bind(console, '%cCoStaff (IRUÓBÉ_AKHIGBÉ)', cssRule), 0);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: BeforeUnloadEvent) {
    console.log(event);
  }

  detectBrowser() {
    console.log('Detecting browser...');
    const userAgent = window.navigator.userAgent;
    console.log(userAgent);
    if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
      console.log("we are here 1");
    } else {
      console.log("Different browser detected.");
    }
  }

  ngOnDestroy() {
    // Add necessary cleanup code here if needed
  }
}
