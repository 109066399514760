import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-public-footer',
  template: `
      <div class="footer">
         <span>
            {{footerText}}
         </span>
         <a routerLink="{{routerLink}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <span>
               {{routerLinkName}}
            </span>
         </a>
      </div>
   `,
  styleUrls: ['../../../../styles/public-footer.css']
})
export class PublicFooterComponent implements OnInit {
   @Input() footerText: string = '';
   @Input() routerLink: string = '';
   @Input() routerLinkName: string = '';
   
   constructor() {}

   ngOnInit(): void {}

   
}