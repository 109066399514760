import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-body-card',
  template: `    
    <div class="body-container">
      <div class="body-card {{other_classes}}">
        <ng-container [ngTemplateOutlet]="bodyTemplate">
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: ['../../../../styles/card-reuse.css']
})


export class BodyCardComponent implements OnInit {
  @Input() other_classes!: string;
  @Input() CardName!: string;
  @Input() bodyTemplate!: TemplateRef<any>;

  constructor() {

  }

  ngOnInit(): void {

  }

}
