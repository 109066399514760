import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/core/services/menu-service';
import { Select } from '@ngxs/store';
import { AppState } from 'src/app/store/app.state';
import { User } from 'src/app/shared/models/user-model';
import { Observable, takeUntil } from 'rxjs';
import { AuthenticationService } from 'src/app/core/api/authentication/authentication.service';
import { RowItem } from '../row-item/row-item-model';
import { SubscriptionManagementDirective } from 'src/app/core/directives/subscription-management.directive';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.css'],
  providers: [MenuService],
})
export class ProfileMenuComponent extends SubscriptionManagementDirective implements OnInit {
  @Select(AppState.getUserProfile) $userProfile!: Observable<User>;
  user!: User;

  iconBasePath = '../../assets/icons/sidebar/';
  accounts: RowItem[] = [];
  settings_menu_items: RowItem[] = [
    new RowItem({
      title: 'View Profile',
      icon: this.iconSrc('profile-icon.svg'),
      onClickHandler: this.navigateTo('support'),
    }),
    new RowItem({
      title: 'Settings',
      icon: this.iconSrc('settings-dark.svg'),
      onClickHandler: this.navigateTo('settings'),
    }),
    new RowItem({
      title: 'Support',
      icon: this.iconSrc('support-dark.svg'),
      onClickHandler: this.navigateTo('support'),
    }),
  ];
  logout_menu_item = new RowItem({
    title: 'Log out',
    icon: '../../assets/icons/sign-out-icon.svg',
    onClickHandler: () => this.logout(),
  });

  @Output() onTapEvent = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    public menuService: MenuService
  ) { super() }

  ngOnInit(): void {
    this.$userProfile.pipe(takeUntil(this.unSubscribe)).subscribe((data: any) => {
      if (data != undefined) {
        this.user = data;
        let accs = [];
        accs.push(this.user.email);
        this.accountMenuItems(accs);
      }
    });
  }

  accountMenuItems(accountList: Array<string>) {
    for (var acc of accountList) {
      this.accounts.push(
        new RowItem({
          title: this.user.firstName + ' ' + this.user.lastName,
          subtitle: this.user.email,
          icon: this.user.profileImage,
        })
      );
    }
    this.accounts.push(
      new RowItem({ title: 'Add Account', icon: '../../assets/icons/add.svg' })
    );
  }

  iconSrc(iconName: string): string {
    return this.iconBasePath + iconName;
  }

  navigateTo(route: string) {
    return () => {
      this.router.navigateByUrl('main/' + route);
      this.onTap();
    };
  }

  logout() {
    this.authService.logOut();
  }

  onTap() {
    this.onTapEvent.emit(true);
  }
}