import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { BootstrapNotifyService } from '../services/bootstrapNotify/bootstrap-notify.service';
 
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  errorMessage!: string;
 
  constructor(
    private router: Router,
    private injector: Injector,
    private bootstrapService: BootstrapNotifyService,
  ) {}
 
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        // retry(1),
 
        catchError((error: HttpErrorResponse) => {
          this.errorMessage = error.error;
          console.log(this.errorMessage);
          if (error.error.statusCode === 401 || error.error.statusCode === 400) {
            console.log(error.error);
            // console.log(error.error.error);
            // console.log(error.error.error[0]);
            // console.log(error.error.message);
          }
 
 
          if (!(error.error instanceof ErrorEvent)) {
            console.log('We are in this error mode');
            // server-side error
            switch (error.status) {
              // tslint:disable-next-line:max-line-length
              case 500: {
                this.bootstrapService.error(error.error.message ? error.error.message : 'Something went wrong internally, Our Engineers have been notified of this error. Please try again.');
                break;
              }
              // tslint:disable-next-line:max-line-length
              case 503: {
                this.bootstrapService.error(error.error.message ? error.error.message : 'Something went wrong internally, Our Engineers have been notified of this error. Please try again.');
                break;
              }
              // tslint:disable-next-line:max-line-length
              case 400: {
                this.bootstrapService.error(error.error.error[0] ? error.error.error[0] : 'Something went wrong, we were unable to process your request');
                break;
              }
              case 403: {
                this.bootstrapService.error(error.error.message ? error.error.message : 'Not found');
                break;
              }
              case 404: {
                this.bootstrapService.info(error.error.message ? error.error.message : 'Something went wrong, couldn\'t get to the request');
                break;
              }
              case 0: {
                this.bootstrapService.error(error.error.message ? error.error.message : 'Couldn\'t connect');
                break;
              }
            }
          }
 
          return throwError(this.errorMessage);
 
        })
      );
  }
}