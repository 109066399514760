import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  SetGmailInitState,
  SetRefreshToken,
  SetToken,
  SetUserProfile,
} from 'src/app/store/app.action';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AppState } from 'src/app/store/app.state';
import { LoaderService } from '../../services/loader.service';
import { BootstrapNotifyService } from '../../services/bootstrapNotify/bootstrap-notify.service';
import { EmailService } from '../../services/email.service';
import { SubscriptionManagementDirective } from '../../directives/subscription-management.directive';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends SubscriptionManagementDirective{
  authBaseURL = environment.API_URL;
  user: any | null = null;
  apiSubscription: Subscription | undefined;

  constructor(
    private api: ApiService,
    private router: Router,
    private http: HttpClient,
    private store: Store,
    private emailService: EmailService,
    private bootstrapNotifyService: BootstrapNotifyService,
    private loaderService: LoaderService
  ) {
    super();
  }

  googleRequest(endpoint: string, options?: any): Observable<any> {
    const url = `${this.authBaseURL}/${endpoint}`;
    return this.http.get(url, options);
  }

  signup(payload: any): Observable<any> {
    return this.api.postRequest('auth/signup', null, payload).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  // googleSignup(): Observable<HttpResponse<any>> {
  //   return this.googleRequest('/auth/google-login', { observe: 'response' });
  // }

  googleSignup(): Observable<HttpResponse<any>> {
    return this.googleRequest('auth/google-login', { observe: 'response' }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getBusinessType(): Observable<any> {
    return this.api.getRequest('setup/getbusinesstypes').pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  login(data: any): Observable<any> {
    return this.api.postRequest('auth/login', null, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  verifyGoogleToken(data: any): Observable<any> {
    return this.api.postRequest('auth/getuserbytoken', null, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  forgotPassword(payload: any): Observable<any> {
    return this.api.postRequest('auth/forgotpassword', null, payload).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  emailExist(payload: any): Observable<any> {
    return this.api.getRequest('auth/email-exist?email=' + payload).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  resetPassword(payload: any): Observable<any> {
    return this.api.postRequest('auth/passwordreset', null, payload).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  activateAccount(payload: any): Observable<any> {
    return this.api.postRequest('auth/activateaccount', null, payload).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  resendActivationLink(payload: any): Observable<any> {
    return this.api.postRequest('auth/resendActivationLink', null, payload).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  resendActivationLinkByEmail(email: any): Observable<any> {
    return this.api.getRequest('auth/resendActivationLinkByEmail?email=' + email).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  refreshToken(refreshToken: string): Observable<any> {
    return this.api.postRequest(`${this.authBaseURL}auth/regenerate-auth-token`, null, {
      headers: { refreshToken: refreshToken },
    })
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  logOut() {
    this.clearStateMgt();
  }

  clearStateMgt() {
    this.store.dispatch(new SetToken(''));
    this.store.dispatch(new SetRefreshToken(''));
    this.store.dispatch(new SetUserProfile(''));
    this.store.dispatch(new SetGmailInitState(false));
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/auth/login']);
  }
}