import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-modal',
  template: `
    <div class="modal-wrapper">
      <div class="modal-content">
        <span>
        <mat-icon *ngIf="goodStatus && !warningStatus" class="material-icons icon">
          <!-- {{modalIcon}} -->
          task_alt
        </mat-icon>
        <mat-icon *ngIf="!goodStatus && !warningStatus" class="material-icons bad-icon">
          <!-- {{modalIcon}} -->
          dangerous
        </mat-icon>
        <mat-icon *ngIf="!goodStatus && warningStatus" class="material-icons warning-icon">
          <!-- {{modalIcon}} -->
          warning
        </mat-icon>

          <br>
          <h3 class="modalTitle">
            {{modalHeaderTitle}}
          </h3>
          <p class="modalMessage">
            {{modalMessage}}
          </p>
          <span class="modalMessage1">
            {{modalSecondMessage}}
          </span>
        </span>
        <div class="modalFooter" *ngIf="!twoButtonAction">
          <br>
          <button class="footerbtn" (click)="action()">
            {{actionBtnText}}
          </button>
        </div>
        <div class="modalFooter footer-grid" *ngIf="twoButtonAction">
          <div style="display: flex; justify-content: end">
            <!-- <button mat-button (click)="action()" class="first-button">
              {{actionBtnText}}
            </button> -->
            <app-flat-button
              [type]="'button'"
              text="{{actionBtnText}}"
              fontSize="0.9rem"
              [isDisabled]=false
              (btnClick)="action()"
            >
            </app-flat-button>
          </div>
          <div style="display: flex; justify-content: start">
            <!-- <button mat-flat-button color="warn" (click)="closeModal()">
              {{closeBtnText}}
            </button> -->
            <app-primary-button
              [type]="'button'" 
              [iconButton]="false"
              [hideToolTip]="true"
              text="{{closeBtnText}}"
              fontFamily="SoraMedium"
              fontSize="0.92rem"
              [isDisabled]="false"
              (btnClick)="closeModal()"
            >
            </app-primary-button>
          </div>
        </div>
      </div>
    </div>

  `,
  styleUrls: ['../../../../styles/notification-modal.css']
})

export class NotificationModalComponent implements OnInit {

  @Input() modalIcon!: string;
  @Input() goodStatus = false;
  @Input() warningStatus = false;
  @Input() twoButtonAction = false;
  @Input() modalHeaderTitle!: string;
  @Input() modalMessage!: string;
  @Input() modalSecondMessage!: string;
  @Input() actionBtnText!: string;
  @Input() closeBtnText!: string;
  @Input() tooltipText: string = ''; // Tooltip text
  public showTooltip = false;

  @Output() modalAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeModalAction: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog)
  { }


  ngOnInit(): void {
  }

  action(): void {
    this.modalAction.emit();
  }

  closeModal(): void {
    this.closeModalAction.emit();
  }

}
