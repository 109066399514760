<div class="profile-footer" (click)="toggleProfileMenu()">
  <img
    class="profile-image"
    [src]="imageUrl"
    alt="Profile Image"
  />
  <div class="user-info" *ngIf="!isCollapsed">
    <span class="user-name">{{ userName }}</span>
  </div>
  <img
    *ngIf="!isCollapsed"
    [src]="profileMenuIcon"
    alt="Submenu icon"
    (click)="toggleProfileMenu()"
  />
</div>
<div class="profile-menu" *ngIf="isActive">
  <app-profile-menu
    (onTapEvent)="onProfileMenuTapListener($event)"
  ></app-profile-menu>
</div>