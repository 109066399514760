import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '../../store/app.state';
import { Observable } from 'rxjs';
import { SetToken } from 'src/app/store/app.action';

@Injectable({
  providedIn: 'root'
})

export class TokenService {
  private token: string | null = null;
  private refreshToken: string | null = null;

  constructor(private store: Store) {
    this.store.select(AppState.getToken).subscribe((token: any) => {
      this.token = token;
    });

    this.store.select(AppState.getRefreshToken).subscribe((refreshToken: any) => {
      this.refreshToken = refreshToken;
    });
  }

  getToken(): string | null {
    return this.token;
  }

  getRefreshToken(): string | null {
    return this.refreshToken;
  }

  setToken(token: string): void {
    // Logic to update the token in the store
    this.store.dispatch(new SetToken(token));
  }

  refreshTokenIfNeeded(): Observable<string> {
    // Logic to refresh the token if needed
    // Example:
    if (this.refreshToken) {
      // Call an API to refresh the token using this.refreshToken
      // Return an observable that emits the new token
    }
    return new Observable<string>((observer) => {
      observer.next(this.token as string);
      observer.complete();
    });
  }
}
